// src/features/birthdata/ProfileManager.scss
@import '../../styles/variables.scss';

.profile-manager {
  &__container {
    border: 1px solid white;
    padding: 4px;
    max-width: 200px;
    background-color: rgba(0, 0, 0, 0.33);
    position: relative;
    display: flex;
  }

  &__profile-name {
    border: 1px solid white;
    padding: 4px;
    max-width: 200px;
    background-color: rgba(0, 0, 0, 0.33);
  }

  &__profile-name-content {
    display: flex;
    gap: 4px;
    align-items: center;
    color: white;
  }

  &__profile-title {
    color: white;
    font-size: 12px;
    width: 128px;
    padding: 8px;
  }

  &__tag-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__tag-icon {
    margin-right: 4px;
  }

  &__tag-input {
    flex: 1;
    padding: 8px;
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    color: white;
    font-size: 12px;
    max-width: 64px;
  }

  &__controls {
    display: flex;
    flex-direction: column;
    height: 64px;
  }

  &__save-form {
    border: 1px solid white;
    z-index: 1500;
    padding: 4px;
    max-width: 200px;
    background-color: rgba(0, 0, 0, 0.33);
  }

  &__save-form-content {
    display: flex;
    gap: 4px;
    align-items: center;
  }

  &__save-input {
    flex: 1;
    padding: 8px;
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    color: white;
    font-size: 12px;
  }

  &__controls-column {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-left: 8px;
  }
}

// Button styles
.btn {
  cursor: pointer;
  border-radius: 4px;
  
  &--standard {
    padding: 2px 4px;
    background-color: rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.3);
    color: white;
    font-size: 12px;
  }
  
  &--danger {
    padding: 2px 4px;
    background-color: rgba(255, 68, 68, 0.2);
    border: 1px solid #ff4444;
    color: white;
    font-size: 10px;
  }
  
  &--dropdown {
    font-size: 10px;
    padding: 4px;
    width: 24px;
    background-color: rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.5);
    color: white;
  }
  
  &--save {
    font-size: 10px;
    padding: 2px 4px;
  }
  
  &--reset {
    font-size: 10px;
    padding: 2px 4px;
    background-color: rgba(255, 244, 244, 0.2);
    border: 1px solid #ff4444;
    color: white;
  }
}

// Profile list styles
.profile-list {
  position: relative;
  
  &__dropdown {
    position: absolute;
    top: 100%;
    left: -120px;
    background-color: rgba(0, 0, 0, 0.9);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    padding: 4px;
    z-index: 2000;
    max-height: 360px;
    overflow-y: auto;
    width: 240px;
    min-width: 100%;
  }
  
  &__controls {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    margin-top: 4px;
    padding-top: 4px;
    text-align: center;
    display: flex;
    flex-direction: row;
  }
  
  &__item {
    padding: 8px;
    cursor: pointer;
    color: white;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  &__tag {
    font-size: 10px;
    margin-right: 8px;
    color: black;
    font-weight: 800;
    background-color: rgba(255, 255, 255, 0.54);
    border-radius: 4px;
    padding: 4px;
  }
}

// Utility classes
.flex-1 {
  flex: 1;
}