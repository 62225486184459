/**
 * ZodiacWheel Component Styles
 * 
 * This file contains styles specific to the ZodiacWheel component.
 */

@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/_globals';

.zodiacWheel {
  position: relative;
  margin: 0 auto;
  max-width: 100vw;
  max-height: 100vw;
  pointer-events: none;
  transition: .5s transform; /* Exact same transition as original */
  transform-origin: center;
  overflow: visible;
}

.aspectLine {
  cursor: pointer !important;
  pointer-events: auto !important; 
  transition: stroke 0.2s ease;
  z-index: 5;
  stroke-linecap: round;
  stroke-linejoin: round;
  
  &:hover {
    stroke: white !important;
  }
}

:global(.aspectLine:hover) {
  stroke: white !important;
}

.zodiacSymbol {
  position: absolute;
  transform: translate(-50%, -50%);
  font-weight: bold;
  user-select: none;
  /* Font size is set dynamically in the component */
}

.celestialBody {
  position: absolute;
  user-select: none;
  transform: translate(-50%, -50%);
  
  /* Use global() to reference global classes */
  &:global(.symbolText) {
    font-family: "Noto Sans Symbols", sans-serif;
    font-weight: bold;
    line-height: 1;
  }
}

.zodiacLine {
  stroke: rgba(255, 255, 255, 0.5);
  stroke-width: 1;
  pointer-events: none;
}

.cuspLine {
  stroke: rgba(255, 255, 255, 0.33);
  stroke-width: 1;
  pointer-events: none;
}

.cuspText {
  fill: rgba(255, 255, 255, 0.33);
  font-size: 10px;
  text-anchor: middle;
  dominant-baseline: middle;
  pointer-events: none;
}

.bodyTick {
  stroke-width: 2;
  opacity: 0.8;
  pointer-events: none;
}

.wheelCircle {
  &.outer {
    fill: rgba(0, 0, 0, 0.3);
    stroke: rgba(255, 255, 255, 0.2);
    stroke-width: 1;
    pointer-events: none;
  }
  
  &.main {
    fill: rgba(0, 0, 0, 0.3);
    stroke: rgba(255, 255, 255, 0.2);
    stroke-width: 20;
    pointer-events: none;
  }
  
  &.inner {
    fill: none;
    stroke: rgba(255, 255, 255, 0.5);
    stroke-width: 1;
    pointer-events: none;
  }
  
  &.aspectCircle {
    fill: none;
    stroke: rgba(255, 255, 255, 0.5);
    stroke-width: 1;
    pointer-events: none;
  }
}

/* Element colors - using the global variables */
.fire {
  color: var(--element-fire-color);
}

.water {
  color: var(--element-water-color);
}

.air {
  color: var(--element-air-color);
}

.earth {
  color: var(--element-earth-color);
}

/* Ensure SVG is properly styled */
:global(svg) {
  overflow: visible;
}