/**
 * Global Styles
 * 
 * This file contains global styles that should be accessible across the application,
 * regardless of CSS module scoping.
 */

// Symbol text styling for zodiac and planetary symbols
.symbolText {
  font-family: "Noto Sans Symbols", sans-serif;
  font-weight: bold;
  line-height: 1;
}

// Aspect line styling for SVG elements
.aspectLine {
  cursor: pointer;
  pointer-events: auto;
  transition: stroke 0.2s ease;
  z-index: 10;
  
  &:hover {
    stroke: white !important;
  }
}

// Global SVG styling
svg {
  overflow: visible;
}

// Z-index handling for interactive elements
.lineMarker:hover {
  z-index: 1000;
}

// Ensure absolute positioning works consistently
.absolute {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

// Element colors (accessible globally)
:root {
  --element-fire-color: #ff6b6b;
  --element-water-color: #4dabf7;
  --element-air-color: #ffe066;
  --element-earth-color: #51cf66;
}