/**
 * App-specific SCSS Styles
 * 
 * This file contains styles specific to the App component and its children.
 */

@import './styles/variables';
@import './styles/mixins';

// Import fonts
@import url("https://fonts.googleapis.com/css2?family=Andika:ital,wght@0,400;0,700;1,400;1,700&family=Arimo:ital,wght@0,400..700;1,400..700&family=Cardo:ital,wght@0,400;0,700;1,400&family=Hachi+Maru+Pop&family=Noto+Sans+JP:wght@100..900&family=Noto+Sans+Mono:wght@100..900&family=Noto+Sans+Symbols:wght@100..900&family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Noto+Serif+Display:ital,wght@0,100..900;1,100..900&family=Noto+Serif:ital,wght@0,100..900;1,100..900&family=Overpass+Mono:wght@300..700&family=Potta+One&family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap");

:root {
  --safe-area-inset-top: env(safe-area-inset-top);
  --safe-area-inset-right: env(safe-area-inset-right);
  --safe-area-inset-bottom: env(safe-area-inset-bottom);
  --safe-area-inset-left: env(safe-area-inset-left);
//   font-size: 10px;
}

// Mobile UI Modal
.mobileUIModal {
  top: calc(-40px + var(--safe-area-inset-bottom));
}

// Symbol text styles
.symbolText {
  font-family: "Noto Sans Symbols";
  font-weight: bold;
}

.symbolText2 {
  font-family: "Overpass Mono", "sans-serif";
}

// Chart styles - Earth signs
#chart-astrology-radix-signs-5,
#chart-astrology-radix-signs-9,
#chart-astrology-radix-signs-1 {
  fill: #27ae60;
}

// Chart styles - Air signs
#chart-astrology-radix-signs-7,
#chart-astrology-radix-signs-11,
#chart-astrology-radix-signs-3 {
  fill: #87ceeb;
}

// Chart styles - Fire signs
#chart-astrology-radix-signs-6,
#chart-astrology-radix-signs-10,
#chart-astrology-radix-signs-2 {
  fill: #ffff66;
}

// Chart styles - Fire planets
#chart-astrology-radix-planets {
  &-Jupiter,
  &-Sun,
  &-Mars {
    & + text,
    path {
      stroke: red;
    }
  }
}

// Chart styles - Earth planets
#chart-astrology-radix-planets {
  &-Saturn,
  &-Venus {
    & + text,
    path {
      stroke: green;
    }
  }
}

// Chart styles - Air planets
#chart-astrology-radix-planets {
  &-Mercury,
  &-Uranus {
    & + text,
    path {
      stroke: #cccc00;
    }
  }
}

// Chart styles - Water planets
#chart-astrology-radix-planets {
  &-Neptune,
  &-Moon,
  &-Pluto {
    & + text,
    path {
      stroke: blue;
    }
  }
}

// Table styles
table {
  // Base table styles
}

// Last table row
.lasttable {
  background-color: #493323 !important;
  font-weight: 700;
}

// Table cell styles
td,
th {
  padding: 3px 3px !important;
  text-align: center;
}

td {
  border: thin dotted #493323 !important;
  border-collapse: collapse;
}

// Table header
thead {
  background-color: #493323;
  color: white;
}

// Button option styles
.buttonOption {
  .buttonHover {
    font-size: 10px;
    transition: 0.3s all;
    font-weight: bold;
    color: white;
    height: 0px;
    margin-top: 12px;
    margin-bottom: -12px;
    cursor: pointer;
  }
  
  &:hover {
    .buttonHover {
      opacity: 1;
    }
  }
}

// Aspect line hover effect
.aspectLine {
  &:hover {
    stroke: white;
  }
}

// Line marker styles
.lineMarker {
  cursor: pointer;
  
  &:hover {
    z-index: 1000;
  }
}

// Diagonal line styles
.diagonal-line {
  position: absolute;
  width: 1px;
  background-color: currentColor;
  opacity: 0.5;
}