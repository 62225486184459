// src/features/birthdata/BirthDataForm.scss
@import '../../styles/variables.scss';

.birth-data-form {
  &__container {
    display: flex;
    flex-direction: column;
  }
  
  &__spacer {
    height: 4px;
  }
  
  &__date-container {
    width: 160px;
  }
}