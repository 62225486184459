/**
 * Main SCSS Entry Point
 * 
 * This file imports all global styles and serves as the entry point
 * for the application's styling.
 */

// Import global styles
@import './styles/variables';
@import './styles/mixins';
@import './styles/global';
@import './styles/themes';

// Base styles that were previously in index.css
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}