// src/components/forms/LocationInput.scss
@import '../../styles/variables.scss';

.location-input {
  &__container {
    display: flex;
    align-items: center;
    gap: 4px;
    width: min-content;
    margin-right: 8px;
  }
  
  &__autocomplete {
    max-width: 84px;
    font-size: 10px;
    padding: 8px;
  }
  
  &__coordinates {
    display: flex;
    flex-direction: column;
  }
  
  &__coordinate {
    width: 24px;
    font-size: 8px;
  }
  
  &__map-button {
    font-size: 10px;
    padding: 1.6px 3px;
    background-color: rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 8px;
    color: white;
    cursor: pointer;
  }
  
  &__error {
    color: #ff6666;
    font-size: 10px;
    margin-top: 4px;
  }
}

