.symbolInterpretationContainer {
    color: rgba(255, 255, 255, 0.7);
    border: 1px solid transparent;
    padding: 8px;
    /* background-color: ; */
}

.symbolInterpretationContainer:hover {
    color: rgba(255, 255, 255, 0.7);
    border: 1px solid white;
}
