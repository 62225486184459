/**
 * WheelConfig Component Styles
 * 
 * This file contains styles specific to the WheelConfig component.
 */

@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/_globals';

.wheelConfig {
  position: relative;
  width: 100%;
  height: 100%;
}

.synastryWheel {
  margin-top: -8px;
  padding: 0;
  /* Using unitless border-radius to match original inline style */
  border-radius: 320; /* Intentionally unitless to match original code */
  overflow: visible;
}