/**
 * SCSS Theme Styles
 * 
 * This file contains theme-specific styles for the application.
 * It complements the themes.js file by providing SCSS access to theme variables.
 */

@import './variables';

// Theme-specific styles
// Dark theme (default)
.theme-dark {
  // Chart-specific styles
  --chart-background: #{$color-surface-dark};
  --chart-grid-color: #{rgba($color-text-dark, 0.1)};
  --chart-text-color: #{$color-text-dark};
  --chart-symbol-color: #{$color-text-dark};
  
  // Aspect line colors
  --aspect-conjunction-color: #{$aspect-conjunction-color};
  --aspect-opposition-color: #{$aspect-opposition-color};
  --aspect-trine-color: #{$aspect-trine-color};
  --aspect-square-color: #{$aspect-square-color};
  --aspect-sextile-color: #{$aspect-sextile-color};
  
  // Planet colors
  --planet-sun-color: #FFB900;
  --planet-moon-color: #EEEEEE;
  --planet-mercury-color: #00B7C3;
  --planet-venus-color: #FF8C00;
  --planet-mars-color: #E81123;
  --planet-jupiter-color: #6B69D6;
  --planet-saturn-color: #744DA9;
  --planet-uranus-color: #018574;
  --planet-neptune-color: #0078D7;
  --planet-pluto-color: #8E8CD8;
  
  // Element colors
  --element-fire-color: #{$element-fire};
  --element-water-color: #{$element-water};
  --element-air-color: #{$element-air};
  --element-earth-color: #{$element-earth};
}

.theme-light {
  // Chart-specific styles
  --chart-background: #{$color-surface-light};
  --chart-grid-color: #{rgba($color-text-light, 0.1)};
  --chart-text-color: #{$color-text-light};
  --chart-symbol-color: #{$color-text-light};
  
  // Aspect line colors remain the same as dark theme
  --aspect-conjunction-color: #{$aspect-conjunction-color};
  --aspect-opposition-color: #{$aspect-opposition-color};
  --aspect-trine-color: #{$aspect-trine-color};
  --aspect-square-color: #{$aspect-square-color};
  --aspect-sextile-color: #{$aspect-sextile-color};
  
  // Planet colors remain the same as dark theme
  --planet-sun-color: #FFB900;
  --planet-moon-color: #505050;
  --planet-mercury-color: #00B7C3;
  --planet-venus-color: #FF8C00;
  --planet-mars-color: #E81123;
  --planet-jupiter-color: #6B69D6;
  --planet-saturn-color: #744DA9;
  --planet-uranus-color: #018574;
  --planet-neptune-color: #0078D7;
  --planet-pluto-color: #8E8CD8;
  
  // Element colors
  --element-fire-color: #{$element-fire};
  --element-water-color: #{$element-water};
  --element-air-color: #{$element-air};
  --element-earth-color: #{$element-earth};
}

// Default theme (dark)
:root {
  @extend .theme-dark;
}

// Mixins for theme-specific styles
@mixin themed() {
  .theme-dark & {
    @content($theme: 'dark');
  }
  
  .theme-light & {
    @content($theme: 'light');
  }
}

// Example usage of themed mixin:
// .my-component {
//   @include themed() using ($theme) {
//     @if $theme == 'dark' {
//       background-color: $color-surface-dark;
//     } @else {
//       background-color: $color-surface-light;
//     }
//   }
// }