/**
 * Global SCSS Styles
 * 
 * This file contains global styles for the application.
 * It includes reset styles, typography, and global utility classes.
 */

// Import SCSS variables and mixins
@import './variables';
@import './mixins';

/* Reset and base styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  height: 100%;
  width: 100%;
  font-family: var(--font-family-primary);
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
  color: var(--color-text);
  background-color: var(--color-background);
}

#root {
  height: 100%;
  width: 100%;
}

/* Typography */
h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-family-heading);
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height-heading);
  margin-bottom: var(--spacing-md);
}

h1 {
  @include heading-1;
}

h2 {
  @include heading-2;
}

h3 {
  @include heading-3;
}

h4 {
  @include heading-4;
}

p {
  margin-bottom: var(--spacing-md);
}

a {
  color: var(--color-primary);
  text-decoration: none;
  transition: color 0.2s ease;
  
  &:hover {
    color: var(--color-primary-dark);
    text-decoration: underline;
  }
}

/* Utility classes */
.container {
  @include container;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.hidden {
  display: none !important;
}

.visually-hidden {
  @include visually-hidden;
}

/* Flexbox utilities */
.flex {
  @include flex;
}

.flex-column {
  @include flex-column;
}

.flex-row {
  @include flex-row;
}

.flex-wrap {
  @include flex-wrap;
}

.flex-center {
  @include flex-center;
}

.flex-between {
  @include flex-between;
}

.flex-around {
  @include flex-around;
}

.flex-grow {
  flex-grow: 1;
}

/* Spacing utilities */
.m-0 { margin: 0; }
.m-1 { margin: var(--spacing-xs); }
.m-2 { margin: var(--spacing-sm); }
.m-3 { margin: var(--spacing-md); }
.m-4 { margin: var(--spacing-lg); }
.m-5 { margin: var(--spacing-xl); }

.mt-0 { margin-top: 0; }
.mt-1 { margin-top: var(--spacing-xs); }
.mt-2 { margin-top: var(--spacing-sm); }
.mt-3 { margin-top: var(--spacing-md); }
.mt-4 { margin-top: var(--spacing-lg); }
.mt-5 { margin-top: var(--spacing-xl); }

.mb-0 { margin-bottom: 0; }
.mb-1 { margin-bottom: var(--spacing-xs); }
.mb-2 { margin-bottom: var(--spacing-sm); }
.mb-3 { margin-bottom: var(--spacing-md); }
.mb-4 { margin-bottom: var(--spacing-lg); }
.mb-5 { margin-bottom: var(--spacing-xl); }

.p-0 { padding: 0; }
.p-1 { padding: var(--spacing-xs); }
.p-2 { padding: var(--spacing-sm); }
.p-3 { padding: var(--spacing-md); }
.p-4 { padding: var(--spacing-lg); }
.p-5 { padding: var(--spacing-xl); }

/* Responsive utilities */
@include breakpoint-down(md) {
  .hide-mobile {
    display: none !important;
  }
}

@include breakpoint-up(md) {
  .hide-desktop {
    display: none !important;
  }
}

/* Button styles */
.btn {
  @include button-base;
  
  &.btn-primary {
    @include button-primary;
  }
  
  &.btn-secondary {
    @include button-secondary;
  }
  
  &.btn-outline {
    @include button-outline;
  }
}

/* Form styles */
input, select, textarea {
  font-family: var(--font-family-primary);
  font-size: var(--font-size-base);
  padding: var(--spacing-sm);
  border: var(--border-width-thin) solid var(--color-border);
  border-radius: var(--border-radius-md);
  background-color: var(--color-surface);
  color: var(--color-text);
  
  &:focus {
    outline: none;
    border-color: var(--color-primary);
  }
}

/* Card styles */
.card {
  background-color: var(--color-surface);
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-md);
  box-shadow: var(--shadow-md);
}

/* Animation utilities */
.fade-in {
  @include fade-in;
}

.fade-out {
  @include fade-out;
}

// Common input styles
.input-style {
    padding: 2px 4px;
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    color: white;
}