/**
 * SCSS Variables
 * 
 * This file contains SCSS variables for the application.
 * It includes colors, typography, spacing, and other design tokens.
 */

// Colors
$color-primary: #007bff;
$color-primary-light: #4da3ff;
$color-primary-dark: #0056b3;

$color-secondary: #6c757d;
$color-secondary-light: #a1a8ae;
$color-secondary-dark: #494f54;

$color-success: #28a745;
$color-info: #17a2b8;
$color-warning: #ffc107;
$color-danger: #dc3545;

$color-background-dark: #121212;
$color-surface-dark: #1e1e1e;
$color-surface-light-dark: #2c2c2c;

$color-text-dark: #f8f9fa;
$color-text-secondary-dark: #adb5bd;
$color-text-muted-dark: #6c757d;

$color-border-dark: #343a40;
$color-divider-dark: #343a40;

$color-background-light: #f8f9fa;
$color-surface-light: #ffffff;
$color-surface-light-light: #f1f3f5;

$color-text-light: #212529;
$color-text-secondary-light: #495057;
$color-text-muted-light: #6c757d;

$color-border-light: #dee2e6;
$color-divider-light: #e9ecef;

// Typography
$font-family-primary: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
$font-family-heading: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
$font-family-monospace: 'Space Mono', 'Courier New', monospace;

$font-size-xs: 0.75rem;    // 12px
$font-size-sm: 0.875rem;   // 14px
$font-size-base: 1rem;     // 16px
$font-size-md: 1.125rem;   // 18px
$font-size-lg: 1.25rem;    // 20px
$font-size-xl: 1.5rem;     // 24px
$font-size-xxl: 2rem;      // 32px
$font-size-xxxl: 2.5rem;   // 40px

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

$line-height-base: 1.5;
$line-height-heading: 1.2;
$line-height-tight: 1.25;

// Spacing
$spacing-xs: 0.25rem;      // 4px
$spacing-sm: 0.5rem;       // 8px
$spacing-md: 1rem;         // 16px
$spacing-lg: 1.5rem;       // 24px
$spacing-xl: 2rem;         // 32px
$spacing-xxl: 3rem;        // 48px

// Layout
$container-max-width: 1200px;
$sidebar-width: 280px;
$header-height: 60px;

// Borders
$border-radius-sm: 0.125rem;  // 2px
$border-radius-md: 0.25rem;   // 4px
$border-radius-lg: 0.5rem;    // 8px
$border-radius-xl: 1rem;      // 16px
$border-radius-circle: 50%;

$border-width-thin: 1px;
$border-width-thick: 2px;

// Shadows
$shadow-sm: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
$shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
$shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
$shadow-xl: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);

// Transitions
$transition-fast: 0.1s ease;
$transition-base: 0.2s ease;
$transition-slow: 0.3s ease;

// Z-index
$z-index-dropdown: 1000;
$z-index-sticky: 1020;
$z-index-fixed: 1030;
$z-index-modal-backdrop: 1040;
$z-index-modal: 1050;
$z-index-popover: 1060;
$z-index-tooltip: 1070;

// Breakpoints

// Element colors
$element-fire: #FF4136;
$element-water: #0074D9;
$element-air: #7FDBFF;
$element-earth: #FFB700;

// Aspect colors
$aspect-conjunction-color: rgba(255, 255, 255, 1);
$aspect-opposition-color: rgba(255, 0, 0, 1);
$aspect-trine-color: rgba(255, 0, 255, 1);
$aspect-square-color: rgba(0, 0, 255, 1);
$aspect-sextile-color: rgba(0, 255, 255, 1);
$aspect-quincunx-color: rgba(51, 255, 0, 0.3);

// Planet colors
$planet-sun-color: #FFB900;
$planet-moon-color: #EEEEEE;
$planet-mercury-color: #00B7C3;
$planet-venus-color: #FF8C00;
$planet-mars-color: #E81123;
$planet-jupiter-color: #6B69D6;
$planet-saturn-color: #744DA9;
$planet-uranus-color: #018574;
$planet-neptune-color: #0078D7;
$planet-pluto-color: #8E8CD8;

$breakpoint-xs: 0;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

// CSS Variables for theme switching
:root {
  // Colors
  --color-primary: #{$color-primary};
  --color-primary-light: #{$color-primary-light};
  --color-primary-dark: #{$color-primary-dark};
  
  --color-secondary: #{$color-secondary};
  --color-secondary-light: #{$color-secondary-light};
  --color-secondary-dark: #{$color-secondary-dark};
  
  --color-success: #{$color-success};
  --color-info: #{$color-info};
  --color-warning: #{$color-warning};
  --color-danger: #{$color-danger};
  
  --color-background: #{$color-background-dark};
  --color-surface: #{$color-surface-dark};
  --color-surface-light: #{$color-surface-light-dark};
  
  --color-text: #{$color-text-dark};
  --color-text-secondary: #{$color-text-secondary-dark};
  --color-text-muted: #{$color-text-muted-dark};
  
  --color-border: #{$color-border-dark};
  --color-divider: #{$color-divider-dark};
  
  // Typography
  --font-family-primary: #{$font-family-primary};
  --font-family-heading: #{$font-family-heading};
  --font-family-monospace: #{$font-family-monospace};
  
  --font-size-xs: #{$font-size-xs};
  --font-size-sm: #{$font-size-sm};
  --font-size-base: #{$font-size-base};
  --font-size-md: #{$font-size-md};
  --font-size-lg: #{$font-size-lg};
  --font-size-xl: #{$font-size-xl};
  --font-size-xxl: #{$font-size-xxl};
  --font-size-xxxl: #{$font-size-xxxl};
  
  --font-weight-light: #{$font-weight-light};
  --font-weight-normal: #{$font-weight-normal};
  --font-weight-medium: #{$font-weight-medium};
  --font-weight-bold: #{$font-weight-bold};
  
  --line-height-base: #{$line-height-base};
  --line-height-heading: #{$line-height-heading};
  --line-height-tight: #{$line-height-tight};
  
  // Spacing
  --spacing-xs: #{$spacing-xs};
  --spacing-sm: #{$spacing-sm};
  --spacing-md: #{$spacing-md};
  --spacing-lg: #{$spacing-lg};
  --spacing-xl: #{$spacing-xl};
  --spacing-xxl: #{$spacing-xxl};
  
  // Layout
  --container-max-width: #{$container-max-width};
  --sidebar-width: #{$sidebar-width};
  --header-height: #{$header-height};
  
  // Borders
  --border-radius-sm: #{$border-radius-sm};
  --border-radius-md: #{$border-radius-md};
  --border-radius-lg: #{$border-radius-lg};
  --border-radius-xl: #{$border-radius-xl};
  --border-radius-circle: #{$border-radius-circle};
  
  --border-width-thin: #{$border-width-thin};
  --border-width-thick: #{$border-width-thick};
  
  // Shadows
  --shadow-sm: #{$shadow-sm};
  --shadow-md: #{$shadow-md};
  --shadow-lg: #{$shadow-lg};
  --shadow-xl: #{$shadow-xl};
  
  // Transitions
  --transition-fast: #{$transition-fast};
  --transition-base: #{$transition-base};
  --transition-slow: #{$transition-slow};
  
  // Z-index
  --z-index-dropdown: #{$z-index-dropdown};
  --z-index-sticky: #{$z-index-sticky};
  --z-index-fixed: #{$z-index-fixed};
  --z-index-modal-backdrop: #{$z-index-modal-backdrop};
  --z-index-modal: #{$z-index-modal};
  --z-index-popover: #{$z-index-popover};
  --z-index-tooltip: #{$z-index-tooltip};
  
  // Element colors
  --element-fire: #{$element-fire};
  --element-water: #{$element-water};
  --element-air: #{$element-air};
  --element-earth: #{$element-earth};
  
  // Aspect colors
  --aspect-conjunction-color: #{$aspect-conjunction-color};
  --aspect-opposition-color: #{$aspect-opposition-color};
  --aspect-trine-color: #{$aspect-trine-color};
  --aspect-square-color: #{$aspect-square-color};
  --aspect-sextile-color: #{$aspect-sextile-color};
  --aspect-quincunx-color: #{$aspect-quincunx-color};
  
  // Planet colors
  --planet-sun-color: #{$planet-sun-color};
  --planet-moon-color: #{$planet-moon-color};
  --planet-mercury-color: #{$planet-mercury-color};
  --planet-venus-color: #{$planet-venus-color};
  --planet-mars-color: #{$planet-mars-color};
  --planet-jupiter-color: #{$planet-jupiter-color};
  --planet-saturn-color: #{$planet-saturn-color};
  --planet-uranus-color: #{$planet-uranus-color};
  --planet-neptune-color: #{$planet-neptune-color};
  --planet-pluto-color: #{$planet-pluto-color};
}

/* Dark theme variables (default) */
.theme-dark {
  /* Colors are already set for dark theme in :root */
}

/* Light theme variables */
.theme-light {
  --color-background: #{$color-background-light};
  --color-surface: #{$color-surface-light};
  --color-surface-light: #{$color-surface-light-light};
  
  --color-text: #{$color-text-light};
  --color-text-secondary: #{$color-text-secondary-light};
  --color-text-muted: #{$color-text-muted-light};
  
  --color-border: #{$color-border-light};
  --color-divider: #{$color-divider-light};
}