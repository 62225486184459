/* src/components/LineViewSettings.css */

.line-view-settings {
    border-radius: 4px;
    width: calc(100% - 32px);
    margin-left: 16px;
    margin-right: 16px;
    /* overflow-x: hidden; */
    margin-bottom: 48px;
}

.line-view-settings h2 {
    margin-bottom: 15px;
    font-size: 1.5em;
    text-align: center;
}

.itemToggle + .itemToggle {
    margin-left: 8px;
}

.itemToggleLabel {
    width: 20px;
    height: 20px;
    text-align: right;
    position: relative;
}
.itemToggleLine {
    position: absolute;
    left: 0px;
    font-weight: 800;
    bottom: -4px;
    transform-origin: bottom left;
    transform: rotateZ(-30deg);
}

.itemToggleLabel + .itemToggleLabel {
    margin-left: 8px;
}
.body-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.body-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    font-size: 12px;
}

.body-item:last-child {
    border-bottom: none;
}

.body-symbol {
    font-size: 1.2em;
    margin-right: 10px;
}

.body-name {
    flex-grow: 1;
    color: white;
}

/* Toggle Switch Styles */
.toggle-switch {
    position: relative;
    display: inline-block;
    width: 28px;
    height: 16px;
}

.toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid rgba(255, 255, 255, 0.3);
    transition: 0.4s;
    border-radius: 20px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 2px;
    background-color: rgba(255, 255, 255, 0.3);

    top: 1px;
    transition: 0.4s;
    border-radius: 50%;
}

.toggle-switch input:checked + .slider {
    border: 1px solid white;
}

.toggle-switch input:checked + .slider:before {
    transform: translateX(10px);
    background-color: white;
}

/* Optional: Add focus styles */
/* .toggle-switch input:focus + .slider {
  //box-shadow: 0 0 1px #4caf50;
} */
