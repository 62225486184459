/**
 * SCSS Mixins
 * 
 * This file contains SCSS mixins for the application.
 * Mixins are reusable style patterns that can be included in other SCSS files.
 */

@import 'variables';

// Flexbox mixins
@mixin flex {
  display: flex;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-row {
  display: flex;
  flex-direction: row;
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-between {
  display: flex;
  justify-content: space-between;
}

@mixin flex-around {
  display: flex;
  justify-content: space-around;
}

@mixin flex-wrap {
  flex-wrap: wrap;
}

// Media query mixins
@mixin breakpoint-up($breakpoint) {
  @if $breakpoint == sm {
    @media (min-width: $breakpoint-sm) { @content; }
  } @else if $breakpoint == md {
    @media (min-width: $breakpoint-md) { @content; }
  } @else if $breakpoint == lg {
    @media (min-width: $breakpoint-lg) { @content; }
  } @else if $breakpoint == xl {
    @media (min-width: $breakpoint-xl) { @content; }
  }
}

@mixin breakpoint-down($breakpoint) {
  @if $breakpoint == xs {
    @media (max-width: #{$breakpoint-sm - 1px}) { @content; }
  } @else if $breakpoint == sm {
    @media (max-width: #{$breakpoint-md - 1px}) { @content; }
  } @else if $breakpoint == md {
    @media (max-width: #{$breakpoint-lg - 1px}) { @content; }
  } @else if $breakpoint == lg {
    @media (max-width: #{$breakpoint-xl - 1px}) { @content; }
  }
}

// Typography mixins
@mixin heading-1 {
  font-family: $font-family-heading;
  font-size: $font-size-xxl;
  font-weight: $font-weight-bold;
  line-height: $line-height-heading;
}

@mixin heading-2 {
  font-family: $font-family-heading;
  font-size: $font-size-xl;
  font-weight: $font-weight-bold;
  line-height: $line-height-heading;
}

@mixin heading-3 {
  font-family: $font-family-heading;
  font-size: $font-size-lg;
  font-weight: $font-weight-bold;
  line-height: $line-height-heading;
}

@mixin heading-4 {
  font-family: $font-family-heading;
  font-size: $font-size-md;
  font-weight: $font-weight-bold;
  line-height: $line-height-heading;
}

@mixin body-text {
  font-family: $font-family-primary;
  font-size: $font-size-base;
  font-weight: $font-weight-normal;
  line-height: $line-height-base;
}

@mixin small-text {
  font-family: $font-family-primary;
  font-size: $font-size-sm;
  font-weight: $font-weight-normal;
  line-height: $line-height-base;
}

// Button mixins
@mixin button-base {
  display: inline-block;
  font-family: $font-family-primary;
  font-weight: $font-weight-medium;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: $border-width-thin solid transparent;
  padding: $spacing-sm $spacing-md;
  font-size: $font-size-base;
  line-height: $line-height-base;
  border-radius: $border-radius-md;
  transition: color $transition-base, background-color $transition-base, border-color $transition-base;
}

@mixin button-primary {
  @include button-base;
  background-color: $color-primary;
  border-color: $color-primary;
  color: white;
  
  &:hover, &:focus {
    background-color: $color-primary-dark;
    border-color: $color-primary-dark;
  }
}

@mixin button-secondary {
  @include button-base;
  background-color: $color-secondary;
  border-color: $color-secondary;
  color: white;
  
  &:hover, &:focus {
    background-color: $color-secondary-dark;
    border-color: $color-secondary-dark;
  }
}

@mixin button-outline {
  @include button-base;
  background-color: transparent;
  border-color: currentColor;
  
  &:hover, &:focus {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

// Layout mixins
@mixin container {
  width: 100%;
  max-width: $container-max-width;
  margin-left: auto;
  margin-right: auto;
  padding-left: $spacing-md;
  padding-right: $spacing-md;
}

@mixin clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

// Positioning mixins
@mixin absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin absolute-fill {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

// Visibility mixins
@mixin visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

// Animation mixins
@mixin fade-in($duration: 0.3s) {
  animation: fadeIn $duration ease-in;
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
}

@mixin fade-out($duration: 0.3s) {
  animation: fadeOut $duration ease-out;
  
  @keyframes fadeOut {
    from { opacity: 1; }
    to { opacity: 0; }
  }
}

// Chart-specific mixins
@mixin zodiac-symbol {
  font-size: $font-size-lg;
  font-weight: $font-weight-bold;
}

@mixin aspect-line($color: var(--color-text-secondary), $width: 1px, $style: solid) {
  stroke: $color;
  stroke-width: $width;
  stroke-dasharray: if($style == 'dashed', 5 5, if($style == 'dotted', 2 2, none));
}

@mixin planet-symbol {
  font-size: $font-size-md;
  font-weight: $font-weight-bold;
  text-align: center;
}